/** @jsx jsx */
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, jsx } from "theme-ui";
import BreadCrumbs from "../components/BreadCrumbs";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import RichText2 from "../components/RichText2";
import SEO from "../components/SEO";

const SectionPageTemplate = ({ data, pageContext }) => {
  const {
    pageType,
    title,
    slug,
    navigationTitle,
    parentPage,
    metaDescription,
    hideBreadcrumb,
    hideTitle,
    headerImage,
    contentArea,
    hidden,
    noIndex,
    enableAbTesting,
    showNpsPopup,
    canonicalTag,
  } = data.contentfulPage;

  const { node_locale: locale, language } = pageContext;

  return (
    <Layout
      pageContext={pageContext}
      pageType={pageType}
      enableAbTesting={enableAbTesting}
      showNpsPopup={showNpsPopup}
    >
      <SEO
        lang={pageContext.language}
        title={title}
        description={metaDescription ? metaDescription.internal.content : ""}
        paths={pageContext.paths}
        hidden={hidden}
        noIndex={noIndex}
        canonicalTag={canonicalTag}
      />
      {!hideBreadcrumb && (
        <Container>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            parentPage={parentPage}
            language={language}
          />
        </Container>
      )}
      <Container variant="narrow" id="content">
        {headerImage && <GatsbyImage image={headerImage.gatsbyImageData} />}
        {!hideTitle && <PageTitle>{title}</PageTitle>}
        {contentArea && contentArea.raw && (
          <RichText2
            content={contentArea}
            locale={locale}
            language={language}
          />
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      slug
      navigationTitle
      parentPage {
        ... on ContentfulProductPage {
          slug
          navigationTitle
        }
      }
      canonicalTag
      metaDescription {
        internal {
          content
        }
      }
      hideTitle
      hideBreadcrumb
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }

          ...fragmentContentfulCallToAction
          ...fragmentContentfulComponent
          ...fragmentContentfulLiftUpLinks
          ...fragmentContentfulLiftUpEasyStep
          ...fragmentContentfulAsset
          ...fragmentContentfulInlineImage
          ...fragmentContentfulContentHubLiftUps
          ...fragmentContentfulTwoColumns
          ...fragmentContentfulEmbeddedNotification
          ...fragmentContentfulLiftUpColor
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
        }
      }
      headerImage {
        gatsbyImageData(aspectRatio: 3, width: 1080)
      }
      hidden
      noIndex
      enableAbTesting
      showNpsPopup
    }
  }
`;
export default SectionPageTemplate;
